.iconbuttoon-container {
    display: flex;
    height: fit-content;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background: transparent;
    margin-right: 40px;
}

.iconbuttoon-container:focus {
    border: none;
    outline: none;
}

.iconbuttoon-label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #101C2A;
}

.iconbuttoon-icon {
    margin-right: 6px;
    font-size: 16px;
    line-height: 20px;
}
