.smallbutton-container-green {
    display: flex;
    height: fit-content;
    background: #88D203;
    border-radius: 25px;
    padding: 8px 16px;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
}

.smallbutton-container-green:focus {
    border: none;
    outline: none;
}

.smallbutton-container-grey {
     display: flex;
     height: fit-content;
     background: rgba(143, 146, 161, 0.2);
     border-radius: 25px;
     padding: 8px 16px;
     align-items: center;
     justify-content: center;
     border: none;
     outline: none;
 }

.smallbutton-container-grey:focus {
    border: none;
    outline: none;
}

.smallbutton-label-green {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
}

.smallbutton-label-grey {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #101C2A;

}
