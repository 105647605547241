.searchagent-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    background-image: url("/homepage_header.png");
    background-position: center;
    background-size: 100%;
    background-color: #101C2A;
    height: 200px;
}

.searchagent-separator {
    border: 1px solid #E0E0E0;
    flex: 1
}

.searchagent-results {
    padding: 50px;
}

.searchagent-results-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #101C2A;
    margin-bottom: 25px;
}


@media screen and (max-width: 568px) {
    .searchagent-results {
        padding: 12px;
    }
}