.filterbutton-container {
    display: flex;
    height: fit-content;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 60px;
    padding: 8px 24px;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    cursor: pointer;
}

.filterbutton-label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #88D203;
 }
