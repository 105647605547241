.pricemarker-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-self: center;
    color: white;
    font-size: 14px;
    font-weight: 500;
    width: 58px;
    height: 32px;
    margin-top: -16px;
    text-align: center;
    transform: translate(-50%, -50%);
}

.pricemarker-container:hover {
    cursor: pointer;
}

.pricemarker-container-rent {
    background-image: url("/marker-rent.png");
    background-size: cover;
}

.pricemarker-container-sale {
    background-image: url("/marker-sale.png");
    background-size: cover;
}

.pricemarker-bubble {
    position: absolute;
    flex: 0;
    flex-direction: row;
    align-self: flex-start;
    background-color: #88D203;
    padding: 8px 0;
    border-radius: 15px;
    border-color: #88D203;
    border-width: 0.5px;
}

.pricemarker-dollar {
    color: #FFFFFF;
    font-size: 14px;
    line-height: 20;
    font-weight: 500
}

.pricemarker-amount {
    color: #FFFFFF;
    font-size: 14px;
    line-height: 20;
    font-weight: 500;
}

.pricemarker-arrow {
    background-color: transparent;
    border-width: 4px;
    border-color: transparent;
    border-top-color: #88D203;
    align-self: center;
    margin-top: -9px;
}

.pricemarker-arrowBorder {
    background-color: transparent;
    border-width: 4px;
    border-color: transparent;
    border-top-color: #88D203;
    align-self: center;
    margin-top: 1px;
}
