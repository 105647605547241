.filteroption-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    cursor: pointer;
}

.filteroption-label {
    color: #101C2A;
    font-size: 14px;
    margin-right: 8px;
}
