.chatpreview-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 25px;
    background-color: white;
    margin-bottom: 8px;
}
.chatpreview-container:hover {
    cursor: pointer;
    background-color: #88D20333;
}
.chatpreview-image {
    border-radius: 50px;
    width: 48px;
    height: 48px;
    object-fit: cover;
    padding: 16px;
}
.chatpreview-contentView {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 16px;
}
.chatpreview-nameRow {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between
}
.chatpreview-nameText {
    font-size: 14px;
    color: #1E1F20;
    line-height: 20px;
    font-weight: 500;
}
.chatpreview-messageText {
    font-size: 14px;
    color: #8F92A1;
    line-height: 22px;
}
.chatpreview-timeText {
    font-size: 12px;
    color: #8F92A1;
    line-height: 20px;
}
.chatpreview-badge {
    background-color: #ff5e2d;
    border-radius: 9px;
    width: 18px;
    height: 18px;
    justify-content: center;
    align-items: center
}