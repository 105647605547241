.propertymodal-container {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border-radius: 30px;
    border: none;
    outline: none;
    background-color: white;
    padding: 38px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90vw;
    height: 90vh;
    overflow: scroll;
}

.propertymodal-slider {
    width: 55%;
    height: 100%;
    border-radius: 30px;
}

.react-slideshow-container {
}

.propertymodal-img {
    width: 100%;
    height: auto;
    border-radius: 30px;
}

.each-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    width: 100%;
    height: 70vh;
    border-radius: 30px;
}

.propertymodal-content {
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 38px;
}

.propertymodal-close-btn {
    position: absolute;
    top: 24px;
    right: 24px;
    background: #D8D8D866;
    border-radius: 50px;
    color: #A0A0A0;
    border: none;
    outline: none;
    width: 30px;
    height: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
}

.propertymodal-title {
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 36px;
    color: #101C2A;
}

.propertymodal-subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #101C2A;
}

.propertymodal-subtitle2 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #8F92A1;
}

.propertymodal-price {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #88D203;
}

.propertymodal-listingtype-container{
    margin-top: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.propertymodal-listingtype-icon{
    width: 8px;
    height: 8px;
    background: #FAB31E;
    border-radius: 4px;
    margin-right: 8px;
}

.propertymodal-listingtype-label{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #8F92A1;
}

.propertymodal-description{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: justify;
    padding-right: 6px;
    line-height: 22px;
    color: #8F92A1;
    margin-top: 24px;
    margin-bottom: 24px;
    overflow-y: auto;
    width: 100%;
}

.propertymodal-phone{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #8F92A1;
    margin-top: 24px;
    margin-bottom: 24px;
    gap: 8px;
}

.propertymodal-estimate{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #8F92A1;
    margin-bottom: 3px;
}

.propertymodal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #101C2A;
}

.propertymodal-bottomtabs {
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
}


@media screen and (max-width: 768px) {
    .propertymodal-container {
        height: 90vh;
        width: 100vw;
        flex-direction: column;
        padding: 24px;
        top: 0;
        left: 0;
        right: 0;
        transform: none;
    }
    .propertymodal-content {
        margin-left: 0px;
        width: 100%;
    }
    .propertymodal-slider {
        margin-top: 32px;
        width: 100%;
    }
}