.mobileBanner-container {
    background-color: #88D203;
    display: flex;
    flex: 1;
    flex-direction: row;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 8px;
    padding-bottom: 8px;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1001;
    color: white;
    opacity: 0.9;
}

.mobileBanner-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.mobileBanner-google-badge {
    height: 50px;
    margin-right: 1rem;
}

.mobileBanner-apple-badge {
    height: 40px;
    margin-right: 1rem;
}

.mobileBanner-text {
    font-weight: 600;
}

.mobileBanner-close {
    cursor: pointer;
}

.mobileBanner-close path {
    stroke: white;
    stroke-width: 3;
}

@media screen and (max-width: 768px) {
    .mobileBanner-container {
        padding-left: 16px;
        padding-right: 16px;
        top: 0;
        bottom: unset;
        position: sticky;
    }

    .mobileBanner-google-badge {
        height: 30px;
        margin-right: 0.5rem;
    }

    .mobileBanner-apple-badge {
        height: 25px;
        margin-right: 0.5rem;
    }

    .mobileBanner-text {
        font-size: 12px;
    }
}