.secondarybutton-container {
    display: flex;
    height: fit-content;
    background: white;
    border-radius: 60px;
    padding: 14px 70px;
    align-items: center;
    justify-content: center;
    border: 2px solid #E0E0E0;
    outline: none;
}

.secondarybutton-container:focus {
    border: 2px solid #E0E0E0;
    outline: none;
}

.secondarybutton-label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #101C2A;
 }
