.searchbar-wrapper {
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
}

.searchbar-bar {
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    height: 65px;
    mix-blend-mode: normal;
    border-radius: 50px;
    padding: 0 24px;
    border: 1px solid #E0E0E0;
}

.searchbar-input {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: rgba(16, 28, 42, 0.4);
    flex: 1;
    align-self: center;
    margin: 15px 0px;
    outline: none;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.searchbar-input:focus{
    outline: none;
}

.searchbar-icon {
    color: #88D203;
    align-self: center;
    margin-right: 16px;
}

.searchbar-list {
    padding: 8px;
}

.searchbar-dropdown-container {
    position: absolute;
    z-index: 1001;
    padding: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
}

.searchbar-dropdown-item {
    padding: 8px;
    color: rgba(16, 28, 42, 0.4);
    font-weight: 600;
}