.myprofile-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    background-image: url("/homepage_header.png");
    background-position: center;
    background-size: 100%;
    background-color: #101C2A;
    height: 200px;
    margin-bottom: 50px;
}

.myprofile-content {
    background: rgba(228, 228, 231, 0.2);
    border-radius: 25px;
    padding: 30px;
    margin: 0 15%;
    padding-bottom: 64px;
}

.myprofile-bio {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #101C2A;
    margin-top: 40px;
    margin-bottom: 32px;
}

.myprofile-btns-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.myprofile-separator {
    border: 1px solid #E0E0E0;
    width: 100%;
    margin-bottom: 16px;
}

.myprofile-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #101C2A;
    margin-bottom: 8px;
    margin-top: 24px;
}

.myprofile-label-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.myprofile-label-text {
    color: #8F92A1;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 8px;
}

.myprofile-map {
    width: 100%;
    height: 300px;
    margin-bottom: 16px;
}

.myprofile-property-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
}

.myprofile-delete-btn {
    background: #D8D8D866;
    border-radius: 50px;
    color: #A0A0A0;
    border: none;
    outline: none;
    width: 60px;
    height: 60px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.myprofile-delete-account-btn {
    width: fit-content;
    gap: 8px;
    padding: 8px 16px;
}

.myprofile-delete-btn:hover {
    background: #D8D8D8;
}