.reviewcard-container {
    width: 100%;
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.reviewcard-header {
    border-radius: 25px;
    display: flex;
    flex: 1;
    padding: 16px;
    margin-bottom: 16px;
    background-position: center;
    background-size: cover;
    min-width: 150px;
    width: 150px;
    min-height: 150px;
    height: 150px;
}

.reviewcard-touchableHeader {
}

.reviewcard-headerContent {
    flex-direction: row;
    justify-content: space-between
}

.reviewcard-headerLeftLabelContainer {
    border-radius: 25px;
    background-color: #ffffff77;
    padding: 8px 16px;
    justify-content: center
}

.reviewcard-headerLeftLabelText {
    color: #ffffff;
    text-align: center;
}

.reviewcard-headerRightLabelContainer {
    display: flex;
    padding: 8px 16px;
    flex-direction: row
}

.reviewcard-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.reviewcard-overallContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 20px;
}

.reviewcard-addressText {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #101C2A;
}

.reviewcard-descriptionText {
    margin-top: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #8F92A1;
}
