.listingtypemarker-container {
    flex-direction: column;
    align-self: flex-start;
    color: white;
    transform: translate(-50%, -50%);
}

.listingtypemarker-container:hover {
    cursor: pointer;
}

.listingtypemarker-bubble {
    position: absolute;
    flex: 0;
    flex-direction: row;
    align-self: flex-start;
    background-color: #88D203;
    padding: 8px 0;
    border-radius: 15px;
    border-color: #88D203;
    border-width: 0.5px;
}

.listingtypemarker-dollar {
    color: #FFFFFF;
    font-size: 14px;
    line-height: 20;
    font-weight: 500
}

.listingtypemarker-amount {
    color: #FFFFFF;
    font-size: 14px;
    line-height: 20;
    font-weight: 500;
}

.listingtypemarker-arrow {
    background-color: transparent;
    border-width: 4px;
    border-color: transparent;
    border-top-color: #88D203;
    align-self: center;
    margin-top: -9px;
}

.listingtypemarker-arrowBorder {
    background-color: transparent;
    border-width: 4px;
    border-color: transparent;
    border-top-color: #88D203;
    align-self: center;
    margin-top: 1px;
}
