.sendsupportmessage-container {
    width: 100%;
}

.sendsupportmessage-title {
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 36px;
    color: #101C2A;
}

.sendsupportmessage-description{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #8F92A1;
    margin-top: 24px;
    margin-bottom: 24px;
}
