.bedsbathsfiltermodal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border-radius: 30px;
    border: none;
    outline: none;
    background-color: white;
    padding: 38px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.bedsbathsfiltermodal-content {
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.bedsbathsfiltermodal-options-title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #101C2A;
    margin-bottom: 12px;
}

.bedsbathsfiltermodal-options {
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
}

.bedsbathsfiltermodal-close-btn {
    background: #D8D8D866;
    border-radius: 50px;
    color: #A0A0A0;
    border: none;
    outline: none;
    width: 30px;
    height: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
}

.bedsbathsfiltermodal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #101C2A;
}
