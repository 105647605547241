.contactlist-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
}
.contactlist-header {
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 16px
}
.contactlist-input {
    flex: 1;
    height: 40px
}
.contactlist-scrollView {
    flex-direction: column;
    display: flex;
    flex: 1;
    padding: 16px;
    overflow-y: scroll;
}
.contactlist-emptyStateText {
    text-align: center;
    color: grey
}