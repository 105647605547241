.support-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.support-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
}

.support-header-image {
     height: 300px;
 }

.support-message-col {
    padding: 30px;
    width: 40%;
}

@media screen and (max-width: 576px) {
    .support-container {
        padding: 16px;
    }
    .support-message-col {
        width: 100%;
    }
}