.chatinput-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 16px;
    gap: 16px;
}
.chatinput-icon {
    margin-right: 8px
}
.chatinput-input {
    font-weight: 500;
    font-size: 14px;
    line-height: 20;
    color: #8F92A1;
    flex: 1
}