.input-container {
    width: 100%;
    margin-bottom: 8px;
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box;   
    box-sizing: border-box;
}

.input-el {
    border: none;
    outline: none;
    border-bottom: 1px solid #8F92A177;
    padding: 8px 16px;
    width: 100%;
    border-radius: 20px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
    background-color: white;
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box;   
    box-sizing: border-box;
}

.input-el:focus {
    border: none;
    outline: none;
    border-bottom: 1px solid #8F92A177;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: box-shadow 0.2s ease-in-out;
 }

 .input-label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #1E1F20;
    margin-bottom: 8px;
}

.input-error {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
    color: rgb(180, 0, 0);
    margin-top: 8px;
}

/** Override of the default input style from react-phone-number-input/style.css */
.PhoneInputInput {
    border: none !important;
    outline: none !important;
}

.input-el-otp {
    padding: 16px;
    margin-left: 8px;
    margin-right: 8px;
    font-size: 22px;
    border-radius: 50px;
    box-sizing: initial;
}