.chat-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    background-color: white
}

.chat-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    padding: 16px
}

.chat-headerTitle {
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    color: #1E1F20;
    margin-right: 48px;
    text-align: center;
    flex: 1
}

.chat-input {
    flex: 1;
    height: 40px
}

.chat-scrollView {
    flex: 1;
    background-color: #e4e4e788;
    padding: 16px;
    overflow-y: scroll;
}

.chat-messagesList {
    padding: 24px
}

.chat-iconButton {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin: 8px;
    background-color: #88D203;
    align-items: center;
    justify-content: center
}

.chat-inputView {
    background-color: #e4e4e788
}
