.login-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 64px;
    height: 100%;
}

.login-logo {
    height: 100px;
}

.login-form-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
    width: 40%;
}

.login-title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #101C2A;
    margin-bottom: 8px;
}

.login-subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #8F92A1;
    margin-bottom: 36px;
}

.login-error {
    margin: 1rem 0;
    color: rgb(180, 0, 0);
    font-size: small;
    font-weight: 300;
}

.login-tip { 
    font-size: 14px;
    color: #8F92A1;
    font-weight: 500;
    text-align: center;
}

@media screen and (max-width: 576px) {
    .login-container {
        padding: 16px;
    }

    .login-form-content {
        width: 100%;
    }
}