.search-container {
    width: 100%;
}

.search-top-bar {
    padding: 0 8px 14px;
}

.search-top-bar-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2rem;
    flex-wrap: wrap;
}

.search-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 80vh;
}

.search-map {
    width: 60%;
    position: relative;
}

.search-draw-button {
    position: absolute;
    margin-top: 8px;
    margin-left: 8px;
    z-index: 998;
}

.search-show-prices-button {
    position: absolute;
    bottom: 0;
    margin-bottom: 8px;
    margin-left: 8px;
    z-index: 998;
}

.search-map-subtitle-container {
    position: absolute;
    bottom: 8px;
    margin-bottom: 16px;
    right: 64px;
    z-index: 998;
    display: flex;
    flex-direction: row;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: center;
    align-items: center;
    background-color: #ffffff99;
    border-radius: 50px;
}

.search-map-subtitle-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.search-results-container {
    width: 40%;
    padding: 0 24px;
    overflow-y: auto;
}

.search-results-title {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    color: #101C2A;
    margin-bottom: 16px;
}

.search-results-subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #101C2A;
}

.search-spinner-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.search-results-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.search-mobile-controls {
    display: none;
}

@media screen and (max-width: 1024px) {
    .search-results-container {
        width: 100%;
        height: 100%;
        padding: 0 8px;
    }

    .search-map {
        width: 100%;
    }

    .search-mobile-controls {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        margin-bottom: 32px;
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
        gap: 16px;
        padding: 12px;
        border-radius: 20px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
        z-index: 1000;
        background-color: white;
    }

    .search-mobile-controls-item {
        background-color: #ffffff;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: #88D203;
        cursor: pointer;
    }

    .search-map-subtitle-container {
        right: 0;
        left: 12px;
        margin-bottom: 56px;
    }
}