.footer-container {
    margin: 50px 127px;
    flex: 1;
}

.footer-columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 50px 120px;
}

.footer-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.footer-col-title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #101C2A;
    text-align: left;
    margin-bottom: 12px;
}

.footer-col-item {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #8F92A1;
    text-align: left;
}

.footer-bottom-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.footer-copyright {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    color: #101C2A;
}

.footer-logos-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.footer-social-logo {
    color: #101C2A;
    margin-right: 34px;
    font-size: 24px;
}
