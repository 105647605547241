.usercontact-container {
    background-color: transparent;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
}

.usercontact-img {
    width: 108px;
    height: 108px;
    border-radius: 54px;
    object-fit: cover;
}

.usercontact-content {
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 16px;
}

.usercontact-title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #101C2A;
}

.usercontact-ratingstext {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #8F92A1;
}

.usercontact-ratingsrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    vertical-align: center;
    margin: 11px 0;
}

.usercontact-phonerow {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #8F92A1;
}

.usercontact-phonetext {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #8F92A1;
    margin-left: 6px;
    margin-right: 6px;
    text-decoration: underline;
}

.usercontact-phonetext:hover {
    cursor: pointer;
}

