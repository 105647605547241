.agentsearchresult-container {
    background-color: white;
    flex: 1;
    cursor: pointer;
}

.agentsearchresult-columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.agentsearchresult-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 30%;
}

.agentsearchresult-map {
    margin: 38px;
    height: 100px;
    width: 33%;

    background-image: url("/map-mock.png");
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-size: cover;

    padding: 16px;
}

.agentsearchresult-col-container {
    padding: 38px 16px;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.agentsearchresult-review-date {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #101C2A;
}

.agentsearchresult-review-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #101C2A;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
}

@media (max-width: 768px) {
    .agentsearchresult-col {
        flex: 1;
        width: 100%;
    }
    .agentsearchresult-review-col {
        display: none;
    }
    .agentsearchresult-map {
        margin: 0;
        height: 200px;
    }
}

@media screen and (max-width: 568px) {
    .agentsearchresult-columns {
        flex-direction: column;
    }
    .agentsearchresult-map {
        box-sizing: border-box;
        margin: 0;
        width: 100%;
        height: 200px;
    }
}