.sell-page {
    padding-bottom: 60px;
}

.sell-page > input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.sell-page > input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.sell-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    background-image: url("/header-map.png");
    background-size: cover;
    background-color: #101C2A;
    padding-bottom: 72px;
    padding-top: 240px;
}

.sell-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 32px 30%;
    flex: 1;
}

.sell-title {
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #101C2A;
    text-align: left;
}

.sell-label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #1E1F20;
}

.sell-input-container {
    margin-bottom: 16px;
    margin-right: 32px;
    display: flex;
    flex-direction: column;
    flex: 1 1;
}

.sell-form-row {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    margin-bottom: 16px;
    width: 100%;
}

.sell-map-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    width: 100%;
    height: 300px;
}

.sell-pics-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

.sell-pic-container {
    margin: 5px;
    border-radius: 25px;
    width: 100px;
    height: 100px;
    filter: drop-shadow(2px 4px 15px rgba(0, 0, 0, 0.2));
}

.sell-pic {
    width: 100%;
    height: 100%;
    border-radius: 25px;
}

.sell-pic-cross-container {
    padding: 4px;
    background-color: lightgrey;
    cursor: pointer;
    position: absolute;
    top: 6px;
    right: 6px;
    width: 20px;
    height: 20px;
    border-radius: 13px;
    text-align: center;
}

.sell-pic-cross {
    color: white
}

.sell-upload-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    border: 1px dashed #8F92A1;
    box-sizing: border-box;
    border-radius: 25px;
    cursor: pointer;
    margin-bottom: 25px;
    padding-top: 60px;
    padding-bottom: 48px;
}

.sell-upload-label {
    margin-top: 30px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #101C2A;
}

@media only screen and (max-width: 768px) {
    .sell-container {
        padding: 32px 12px;
    }
    .sell-input-container {
        margin-right: 0px;
    }
}