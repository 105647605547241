.accountsettings-container {
    display: flex;
    flex: 1;
    flex-direction: column;
}
.accountsettings-scrollView {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 20% 5rem 20%;
    background-color: #e4e4e788;
}
.accountsettings-image {
    border-radius: 39px;
    width: 78px;
    height: 78px;
    object-fit: cover
}
.accountsettings-titleText {
    font-size: 18px;
    color: #101C2A;
    line-height: 30px;
    font-weight: 500
}
.accountsettings-tipText {
    font-size: 14px;
    color: #8F92A1;
    line-height: 20px;
    font-weight: 500;
    margin: 0 8px;
}
.accountsettings-orText {
    font-size: 14px;
    color: #8F92A1;
    line-height: 20px;
    font-weight: 500;
    margin: 0 8px;
    text-align: center
}
.accountsettings-inputContainer {
    margin-bottom: 24px
}
.accountsettings-inputLabel {
    margin-top: 8px;
    font-size: 12px;
    color: #1E1F20;
    line-height: 20px;
    font-weight: 500;
    text-transform: uppercase
}
.accountsettings-footer {
    margin-top: 128px;
    justify-content: center;
    align-items: center
}
.accountsettings-privacyPolicyLabel {
    font-size: 14px;
    color: #8F92A1;
    line-height: 20px;
    font-weight: 500
}
.accountsettings-privacyPolicyButton {
    font-size: 14px;
    color: #88D203;
    line-height: 20px;
    font-weight: 500
}