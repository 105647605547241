.propertyhorizontalcard-container {
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    border-radius: 25px;
    padding-right: 16px;
    box-sizing: border-box;
}

.propertyhorizontalcard-container:hover {
    background-color: #ececec;
}

.propertyhorizontalcard-header {
    border-radius: 25px;
    display: flex;
    flex: 1;
    padding: 16px;
    background-position: center;
    background-size: cover;
    min-width: 150px;
    width: 150px;
    min-height: 150px;
    height: 150px;
}

.propertyhorizontalcard-touchableHeader {
}

.propertyhorizontalcard-headerContent {
    flex-direction: row;
    justify-content: space-between;
}

.propertyhorizontalcard-headerLeftLabelContainer {
    border-radius: 25px;
    background-color: #ffffff77;
    padding: 8px 16px;
    justify-content: center
}

.propertyhorizontalcard-headerLeftLabelText {
    color: #ffffff;
    text-align: center;
}

.propertyhorizontalcard-headerRightLabelContainer {
    display: flex;
    padding: 8px 16px;
    flex-direction: row
}

.propertyhorizontalcard-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px;
}

.propertyhorizontalcard-iconButton {
    margin-left: 8px
}

.propertyhorizontalcard-addressContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 20px;
}

.propertyhorizontalcard-addressText {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #101C2A;
}

.propertyhorizontalcard-statusContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 20px;
}

.propertyhorizontalcard-statusIcon {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #88D203;
    margin: 2px
}

.propertyhorizontalcard-statusText {
    font-size: 12px;
    color: #8F92A1;
    margin-left: 4px;
    line-height: 20px;
}

.propertyhorizontalcard-descriptionText {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(143, 146, 161, 0.6);
    white-space: break-spaces;
}

.propertyhorizontalcard-priceText {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #101C2A;
}

.propertyhorizontalcard-optionsContainer {
    margin-top: 8px;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

@media only screen and (max-width: 768px) {
    .propertyhorizontalcard-container {
        flex-direction: column;
        padding-right: 0;
        border: 1px solid #E0E0E0;
    }
    .propertyhorizontalcard-content {
        padding: 8px
    }
    .propertyhorizontalcard-priceText {
        font-size: 20px;
        line-height: 24px;
    }
}