.select-container {
    width: 100%;
    margin-bottom: 8px;
}

.select-el {
    border: none;
    outline: none;
    border-bottom: 2px solid #8F92A177;
    padding-bottom: 8px;
    width: 100%;
}

.select-el:focus {
    border: none;
    outline: none;
    border-bottom: 2px solid #8F92A177;
}

.select-label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #1E1F20;
    margin-bottom: 8px;
}
