.propertycard-container {
    flex: 1 0 40%;
    margin-bottom: 16px;
    margin-left: 8px;
    margin-right: 8px;
    cursor: pointer;
    background-color: white;
    border-radius: 20px;
    padding: 8px;
}

.propertycard-container:hover {
    background-color: #88D20333;
}

.propertycard-header {
    border-radius: 20px;
    display: flex;
    flex: 1;
    padding: 16px;
    margin-bottom: 16px;
    background-position: center;
    background-size: cover;
    /*min-width: 330px;*/
    width: 50%;
    min-height: 200px;
    height: 200px;
}

.propertycard-touchableHeader {
    display: flex;
    flex: 1;
}

.propertycard-headerContent {
    flex-direction: row;
    justify-content: space-between
}

.propertycard-headerLeftLabelContainer {
    border-radius: 25px;
    background-color: #ffffff77;
    padding: 8px 16px;
    justify-content: center
}

.propertycard-headerLeftLabelText {
    color: #ffffff;
    text-align: center;
}

.propertycard-headerRightLabelContainer {
    display: flex;
    padding: 8px 16px;
    flex-direction: row
}

.propertycard-iconButton {
    margin-left: 8px
}

.propertycard-addressContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 20px;
    margin-bottom: 8px;
}

.propertycard-addressText {
    font-size: 12px;
    color: #8F92A1;
    margin-left: 4px;
}

.propertycard-statusContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 20px;
}

.propertycard-statusIcon {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #88D203;
    margin: 2px
}

.propertycard-statusText {
    font-size: 12px;
    color: #8F92A1;
    margin-left: 4px;
}

.propertycard-optionsContainer {
    margin-top: 8px;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

@media screen and (max-width: 576px) {
    .propertycard-container {
        flex: 1 0 90%;
    }
}