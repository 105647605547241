.agentprofile-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    background-image: url("/homepage_header.png");
    background-position: center;
    background-size: 100%;
    background-color: #101C2A;
    height: 200px;
    margin-bottom: 50px;
}

.agentprofile-cols {
    margin: 0 40px;
    display: flex;
    flex-direction: row;
}

.agentprofile-content {
    background: rgba(228, 228, 231, 0.2);
    border-radius: 25px;
    padding: 30px;
    padding-bottom: 72px;
}

.agentprofile-message-col {
    padding: 30px;
    width: 40%;
}

.agentprofile-bio {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #101C2A;
    margin-top: 40px;
    margin-bottom: 32px;
}

.agentprofile-separator {
    border: 1px solid #E0E0E0;
    width: 100%;
    margin-bottom: 16px;
}

.agentprofile-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #101C2A;
    margin-bottom: 8px;
    margin-top: 24px;
}

.agentprofile-label-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.agentprofile-label-text {
    color: #8F92A1;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 8px;
}

.agentprofile-map {
    width: 100%;
    height: 300px;
    margin-bottom: 16px;
}

@media only screen and (max-width: 768px) {
    .agentprofile-message-col {
        display: none;
    }
    .agentprofile-cols {
        margin: 0;
    }
    .agentprofile-content {
        padding: 16px;
    }
}