.navbar-container {
    background-color: white;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    padding-left: 5rem;
    padding-right: 5rem;
    justify-content: space-between;
    align-items: center;
    height: 10vh;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    -webkit-box-shadow: 0px 4px 10px -5px rgba(214,207,214,1);
    -moz-box-shadow: 0px 4px 10px -5px rgba(214,207,214,1);
    box-shadow: 0px 4px 10px -5px rgba(214,207,214,1);
}

.navbar-container a {
    text-decoration: none;
}

.navbar-section {
    display: flex;
    flex: 2 1 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.navbar-expand-btn {
    display: none;
}

.navbar-item {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: black;
    align-self: center;
    padding: 8px;
    border: 2px solid transparent;
    text-align: center;
    margin: 0 1rem;
}

.navbar-item:hover {
    color: #88D203;
}

.navbar-item-active {
    border-bottom: black 2px solid;
}

.navbar-logo {
    margin-left: 100px;
    margin-right: 100px;
    height: 75px;
    display: flex;
    flex: 2 1 0;
}

/* Style The Dropdown Button */
.dropbtn {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: black;
    align-self: center;
    border: none;
    background-color: white;
    padding: 8px;
}

.dropbtn path, .dropbtn polyline {
    stroke: black;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-left: -2rem;
    right: 0;
}

/* Links inside the dropdown */
.dropdown-content .navbar-item {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content .navbar-item:hover {
    color: #88D203;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
}

@media only screen and (min-width: 992px) {
    .navbar-container {
        padding-left: 5rem;
        padding-right: 5rem;
    }

    #collapsible-menu {
        display: none;
    }
}


@media screen and (max-width: 992px) {
    .navbar-container {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .navbar-section {display: none;}
    #collapsible-menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
    }
    .navbar-logo {
        margin: 0;
    }
    .navbar-expand-btn {
        display: block;
    }
  }
