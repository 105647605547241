.chatmessage-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-bottom: 12px
}
.chatmessage-image {
    border-radius: 24px;
    width: 48px;
    height: 48px;
    object-fit: cover;
    margin-right: 16px
}
.chatmessage-contentViewOther {
    flex: 1;
    padding: 16px;
    background-color: #ffffff;
    border-top-left-radius: 0;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px
}
.chatmessage-contentViewMe {
    flex: 1;
    padding: 16px;
    background-color: #88D203;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 0;
    margin-left: 64px
}
.chatmessage-messageTextOther {
    font-size: 14px;
    color: #101C2A;
    line-height: 22px;
}
.chatmessage-messageTextMe {
    font-size: 14px;
    color: #ffffff;
    line-height: 22px;
}

.chatmessage-messageTime {
    font-size: 10px;
    color: #000000;
    line-height: 22px;
    margin-top: 8px;
    text-align: right
}