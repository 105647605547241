.primarybutton-container {
    display: flex;
    height: fit-content;
    background: #88D203;
    border-radius: 60px;
    padding: 14px 70px;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    margin-top: 8px;
}

.primarybutton-container:focus {
    border: none;
    outline: none;
}

.primarybutton-label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
 }
